import React, { useState, useEffect, useRef } from 'react';
import { AFrameRenderer, Marker } from 'react-web-ar';
import { useNavigate } from "react-router-dom";

function SampleAr() {
  let navigate = useNavigate();
  const [arVisible, setArVisible] = useState(false);
  const modelRef = useRef(null);

  const handleShowAr = () => {
    setArVisible(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDisableAR = () => {
    window.location.replace("/AR/SampleAR");
  };

  useEffect(() => {
    if (arVisible && modelRef.current) {
      const model = modelRef.current;
      let startX = 0;
      let currentRotationY = 0;

      const handleTouchStart = (e) => {
        startX = e.touches ? e.touches[0].clientX : e.clientX;
      };

      const handleTouchMove = (e) => {
        e.preventDefault();
        const moveX = e.touches ? e.touches[0].clientX : e.clientX;
        const deltaX = moveX - startX;

        // deltaX ���� Y�� ȸ���� �ݿ�
        currentRotationY += deltaX * 0.8; // ȸ�� �ӵ� ���� (0.1�� �ӵ� ����)
        model.setAttribute('rotation', `0 ${currentRotationY} 0`);

        startX = moveX;
      };

      // �̺�Ʈ ������ �߰�
      const scene = document.querySelector('a-scene');
      scene.addEventListener('touchstart', handleTouchStart, { passive: false });
      scene.addEventListener('touchmove', handleTouchMove, { passive: false });
      scene.addEventListener('mousedown', handleTouchStart);
      scene.addEventListener('mousemove', (e) => {
        if (e.buttons === 1) handleTouchMove(e); // ���� ���콺 ��ư�� ���� ���� ���� ȸ��
      });

      return () => {
        // ������Ʈ �𸶿�Ʈ �� �̺�Ʈ ������ ����
        scene.removeEventListener('touchstart', handleTouchStart);
        scene.removeEventListener('touchmove', handleTouchMove);
        scene.removeEventListener('mousedown', handleTouchStart);
        scene.removeEventListener('mousemove', handleTouchMove);
      };
    }
  }, [arVisible]);

  return (
    <div>
      <button onClick={handleShowAr}>Show Sample AR</button>
      <button onClick={handleBack}>Back</button>
      {arVisible && (
        <div style={{ position: 'fixed', top: '20px', left: '5%', zIndex: 10, textAlign: 'center' }}>
          <button
            style={{
              fontSize: '30px',
              borderRadius: '5px',
              backgroundColor: '#007BFF',
            }}
            onClick={handleDisableAR}
          >
            Disable AR mode
          </button>
        </div>
      )}

      {arVisible && (
        <div>
          <AFrameRenderer inherent={true}  renderer="colorManagement: true">
              <a-camera look-controls="enabled: false" wasd-controls="enabled: false"></a-camera>
              <Marker parameters={{ preset: "hiro" }}>
                <a-entity
                  ref={modelRef}
                  gltf-model="gltf: url(https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/main/2.0/Fox/glTF/Fox.gltf)"
                  position="0 0.5 0"
                  rotation="0 0 0"
                  scale="0.01 0.01 0.01"
                  animation-mixer="clip: *; loop: repeat;"
                >
                </a-entity>
              </Marker>
           
          </AFrameRenderer>
        </div>
      )}
    </div>
  );
}

export default SampleAr;
