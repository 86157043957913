import React, { useState , useRef,  useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Quiz.css';


import { ReactComponent as Q1 } from "../assets/Quiz/ImgTextQ1.svg";
import { ReactComponent as Q2 } from "../assets/Quiz/ImgTextQ2.svg";
import { ReactComponent as Q3 } from "../assets/Quiz/ImgTextQ3.svg";
import { ReactComponent as Q4 } from "../assets/Quiz/ImgTextQ4.svg";
import { ReactComponent as Q5 } from "../assets/Quiz/ImgTextQ5.svg";
import { ReactComponent as Q6 } from "../assets/Quiz/ImgTextQ6.svg";


const quizImages = {
  1: Q1,
  2: Q2,
  3: Q3,
  4: Q4,
  5: Q5,
  6: Q6
};


function Quiz(props) {
  const { id } = useParams();
  const data = props.data;

  const navigate = useNavigate();
  const [answer, setAnswer] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [showAR, setShowAR] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const correctAnswer = data[id].correct;
  let nextQuizId = parseInt(id, 10) + 1;
  const dialogRef = useRef();
  const TotalQuiz = data.length;
  const currentQuiz = data[id];
  const QuizImage = quizImages[currentQuiz.quizID];


  const handleSubmit = () => {
    if (answer.trim() === '') {
      setIsCorrect(null);
    } else if (answer === correctAnswer) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const handleCloseModal = () => {
    dialogRef.current.close();
  };


  const handleShowHint = () => {
    const modalmessage =data[id].hint
    setModalMessage(<p dangerouslySetInnerHTML={{ __html: modalmessage}}/>);
    dialogRef.current.showModal();
  };

  const handleShowAR = () => {
    setShowAR(true);
    const mode = data[id].arMode;
    navigate(`/AR/${mode}`);
  };

  const handleNextQuiz = () => {
    navigate(`/Quiz/${nextQuizId}`);
    setAnswer('');
    setIsCorrect(null);
    setShowHint(false);
  };

  const showResult = () => {
    navigate(`/Result`);
  };

  const QuizContent = ({ content }) => {
    return (
      <div className='quizContentText' dangerouslySetInnerHTML={{ __html: content }} />
    );
  };


  const QuizProgress = ({ totalQuestions, currentQuestion }) => {

    return (
      <div className='layout-progressbar'>
        {Array.from({ length: totalQuestions }).map((_, index) => (
          <div
            key={index}
            className={`Progressbar-part ${index < currentQuestion ? 'completed' : ''}`}
          ></div>
        ))}
      </div>
    );
  };



  return (
    <div className='QuizeBackground' >
       <div className='layout-contents'>
       <QuizProgress totalQuestions={TotalQuiz} currentQuestion={data[id].quizID} />
        </div>
      <div className='QuizContents'>
        
          {QuizImage && <QuizImage style={{ width: '30%',  margin:"0 auto"}} />}

          <QuizContent content={data[id].content} />
            <div >
            {data[id].arMode !== 'none' && !showAR && (
              <button onClick={handleShowAR} className='AR-button' ></button>
            )}
            <button onClick={handleShowHint} className='Hint-button'></button>
            </div>
      </div>
      <dialog ref={dialogRef} className="custom-modal">
        {modalMessage && (
          <>
            <button onClick={handleCloseModal} className="modal-button"></button>
            {modalMessage}
            
            
          </>
        )}
		  </dialog>
      
      
      
        <div className='resultBox'>
        {isCorrect && <div className="ResultCorrect"></div>}
        {isCorrect !=null && !isCorrect && <div className="ResultInCorrect"></div>}
        </div>
        
        <input
          type="text"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="입력"
          className='inputAnswer'
          maxlength='8'
      
        />
        <button onClick={handleSubmit} className='submit-button'></button>
        {isCorrect &&<>
        
        {nextQuizId >= data.length ? (
          <button
            onClick={showResult}
            className='Result-button'
            disabled={!isCorrect}
          >
          </button>
        ) : (
          <button
            onClick={handleNextQuiz}
            className='Next-button'
            disabled={!isCorrect}
          >
          </button>
        )}</>
      }
      </div>
  );
}

export default Quiz;