// App.js
import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from './components/main/Main';
import Result from './components/Result/Result';
import Quize from './components/Quiz/Quiz';
import data from './components/Quiz/data';
import NotFound from './components/main/NotFound';
import SampleAR from './components/sampleobj/sampleAR';
import PanzerAR from './components/AR/panzerAR';
import PeaceDrawingAR from './components/AR/peaceDrawingAR';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/Quiz/:id" element={<Quize data={data} />}></Route>
            <Route path="/Result" element={<Result/>}></Route>
            <Route path="/AR/SampleAR" element={<SampleAR/>}></Route>
            <Route path="/AR/peaceDrawingAR" element={<PeaceDrawingAR/>}></Route>
            <Route path="/AR/panzerAR"  element={<PanzerAR/>}></Route>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
    
  );
}

export default App;