import React , { useState  }from 'react';
import { useNavigate} from "react-router-dom";
import './Main.css';

const Main = (props) => {
    let navigate = useNavigate();
    const [isShowStart, setShowStart] = useState(false);
    const startQuiz = () => {
        navigate(`/Quiz/0`);
      };
      const nextHandle = () =>
      {
        setShowStart(true)
      }
	return (
        <div className='mainBackground'>
            <div className='mainContents'>
         
                <div className='titleContents'>
                    
                </div>

                
                {isShowStart &&
                (
                    <>
                        <div className='ImgOnboarding2'></div>
                        <div className='startMessage'>
                            당신에게 평화와 문화는 어떤 의미인가요?<br />
                            평화와 문화의 의미를 되새기는 <br />시간이 되시길 바랍니다.
                        </div>
                        <button className="startBtn" onClick={startQuiz}></button>
                    </>
                )
                }
                {!isShowStart &&
                (
                    <>
                        <div 
                                className='ImgOnboarding1' 
                                
                            ></div>
                        <div className='startMessage'>
                            평화문화진지에 오신 걸 환영합니다.<br />
                            이 곳은 평화와 문화를 보호하는<br />
                            벙커입니다.<br />
                            <br />
                            이곳이 간직하고 있는 평화와 문화를 <br />당신에게 보여드리려고 합니다.<br />
                        </div>
                        <button className="nextBtn" onClick={nextHandle}></button>
                </>
                )
                }
                
                
            </div>
        </div>
	);
};

export default Main;
