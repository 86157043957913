let data = [
    {
      id: 0,
      url: '/quize/0',
      quizID: '1',
      content: '평화동 옆에는 키가 큰 전망대가 있고 그 아래에는 한국을 지켰던 전투용 차량들이 있습니다. </br></br>“나”는 11명을 태울 수 있는 전투용 차량입니다. 나의 마음에 새겨진 숫자를 더하여, 정답을 맞추시오.',
      hint: 'CM6614 장갑차 위에는 네 자리 숫자가 두개 적혀 있습니다. 두 수를 더하세요.',
      correct: '2067',
      arMode:'PanzerAR'
      
    
    },
    {
        id: 1,
        url: '/quize/1',
        quizID: '2',
        content: '나는 한 때 분단의 상징이었습니다. 그러나 이제는 평화와 화합의 상징이자 통일의 증표가 되었습니다. </br>나는 분단된 당신들의 나라에도 _ _ _ _ 가 허용되길 바랍니다. </br></br>_ _ _ _ 에 들어갈 단어를 적어주세요. ',
        hint: "베를린 장벽을 기준으로 왼쪽에 놓인 안내판에 정답이 적혀 있습니다.'1989년 11월 9일 0000가 허용된 이후 차례로 장벽이 해체되었고'에서 0000에 들어가는 단어를 입력하세요.",
        correct: '자유왕래',
        arMode:'none'
    },
    {
        id: 2,
        url: '/quize/2',
        quizID: '3',
        content: '어느 날 꿈을 꾸고, 나는 이 그림을 그리기로 다짐했습니다. 내 꿈속에는 말뿐인 평화는 없었고, 대신 모두가 한껏 먹고 춤추고 달리고 날아다니는 진정한 평화가 있었습니다. 내게 평화는 한낮의 단잠과 같습니다. 나는 내 꿈이 이어지는 “_ _ _ ” 너머로 가고 싶습니다.</br></br> _ _ _ 에 들어갈 단어를 적어주세요.',
        hint: '이 그림은 평화문화진지 뒤쪽에 그려져 있습니다. <한낮의 꿈, 도봉산 명랑운동회>를 찾아, 화가의 꿈이 이어지는 곳을 찾으시오. (잘 보이지 않는다면 카메라로 찍어서 확대해보세요!)',
        correct: '휴전선',
        arMode:'peaceDrawingAR'
    },
    {
      id: 3,
      url: '/quize/3',
      quizID: '4',
      content: "이곳까지 나를 찾아온 당신을 위해, 명언을 준비했습니다. 유명한 철학자 아리스토텔레스가 했던 말입니다.</br>'희망은 잠자고 있지 않은 인간의 꿈이다. 인간의 꿈이 있는 한, 이 세상은 도전해 볼만하다.'</br></br>꿈을 꾸는 인간을 가리키는 단어를 맞춰주세요. 정답은 모두 일곱 글자입니다.",
      hint: '몽상가를 뜻하는 외국어입니다. 평화문화진지 건물의 한 가운데에는 평화광장이 있습니다. 평화광장에 가서 하늘을 바라보세요. 정답이 그곳에 있습니다.</br>(대문자로 입력하세요.)',
      correct: 'UTOPIST',
      arMode:'none'
    },
    {
      id: 4,
      url: '/quize/4',
      quizID: '5',
      content: "평화가 왜 중요한 지 AI 워커 장군님께 물어보세요. 장군님은 삶의 존엄성과 _ _을 지키기 위해서 평화가 중요하다고 했는데요. 빈칸에 들어갈 대답을 적어주세요. 그 사람과 대화를 통해, 진정한 평화의 의미가 무엇인지 물어보세요.",
      hint: '평화동 전시실에 들어가 AI 워커 장군님께 "평화가 왜 중요해요?"라고 물어보세요. </br>(한국어 질문만)',
      correct: '인권',
      arMode:'none'
    },
    {
      id: 5,
      url: '/quize/5',
      quizID: '6',
      content: "즐거운 탐험이었길 바랍니다. 이제 마지막 문제입니다. 당신에게 문화의 의미에 대해 알려주려고 합니다. </br></br>평화문화진지에서 가장 넓은 화면에는 당신이 그린 그림을 띄울 수 있습니다. 당신이 그릴 수 있는 그림의 종류를 보고, 몇 가지인지 정답을 적어주세요.",
      hint: '평화동 인터랙티브 드로잉 콘텐츠를 시작해 보세요. 그 곳에는 당신이 직접 색깔을 넣어 그릴 수 있는 그림 오브젝트들이 있습니다. 모두 몇 가지의 오브젝트가 있는 지 맞춰보세요. </br>(숫자로 적어주세요.)',
      correct: '8',
      arMode:'none'
    }
  ];
  
  export default data;