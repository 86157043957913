import React, { useState, useRef, useEffect } from 'react';
import { AFrameRenderer, Marker } from 'react-web-ar';
import './ARModeUI.css';

function PeaceDrawingAR() {
  const [arVisible] = useState(true);
  const modelRef = useRef(null);



  const handleDisableAR = () => {
    window.location.replace("/Quiz/2")
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handleClickBrowserBackBtn = () => {
      // �ڷ� ���� ��ư Ŭ�� �� ����� ����
      // ���⼭�� �ƹ��� ���۵� ���� �ʵ��� �����Ͽ� �ڷ� ���� ��ư�� ����ȭ�˴ϴ�.
      window.history.pushState(null, "", window.location.href); // ���� ���¸� �ٽ� push�Ͽ� �ڷ� ���⸦ ����
    };

    window.addEventListener("popstate", handleClickBrowserBackBtn);

    return () => {
      window.removeEventListener("popstate", handleClickBrowserBackBtn);
    };
  }, []);
  
  useEffect(() => {
    if (arVisible && modelRef.current) {
      const model = modelRef.current;
      let startX = 0;
      let currentRotationY = 0;

      const handleTouchStart = (e) => {
        startX = e.touches ? e.touches[0].clientX : e.clientX;
      };

      const handleTouchMove = (e) => {
        e.preventDefault();
        const moveX = e.touches ? e.touches[0].clientX : e.clientX;
        const deltaX = moveX - startX;

        // deltaX ���� Y�� ȸ���� �ݿ�
        currentRotationY += deltaX * 0.8; // ȸ�� �ӵ� ���� (0.1�� �ӵ� ����)
        model.setAttribute('rotation', `${currentRotationY} -90  90`);

        startX = moveX;
      };

      // �̺�Ʈ ������ �߰�
      const scene = document.querySelector('a-scene');
      scene.addEventListener('touchstart', handleTouchStart, { passive: false });
      scene.addEventListener('touchmove', handleTouchMove, { passive: false });
      scene.addEventListener('mousedown', handleTouchStart);
      scene.addEventListener('mousemove', (e) => {
        if (e.buttons === 1) handleTouchMove(e); // ���� ���콺 ��ư�� ���� ���� ���� ȸ��
      });

      return () => {
        // ������Ʈ �𸶿�Ʈ �� �̺�Ʈ ������ ����
        scene.removeEventListener('touchstart', handleTouchStart);
        scene.removeEventListener('touchmove', handleTouchMove);
        scene.removeEventListener('mousedown', handleTouchStart);
        scene.removeEventListener('mousemove', handleTouchMove);
      };
    }
  }, [arVisible]);


  return (
    <div>
      {arVisible && (
        <div style={{position: 'absolute', zIndex: 10, textAlign: 'center ', left: '50%',transform: 'translateX(-50%)',bottom: '10%'}}>
          <button class="disable-button" onClick={handleDisableAR}></button>
         </div>
      )}

      {arVisible && (
        <AFrameRenderer inherent  gesture-detector  renderer="logarithmicDepthBuffer: true; colorManagement: true" vr-mode-ui="enabled: false" arjs="debugUIEnabled: false;">
          <a-camera look-controls="enabled: false" wasd-controls="enabled: false"></a-camera>
          <Marker parameters={{ preset:"pattern", type:"pattern" ,url:"/marker/pattern-AR.patt"}}>
            <a-entity 
              ref={modelRef}
              gltf-model="url(/models/PeaceDrawing.gltf)"
              position="0 0 0"
              rotation="0 -90 90"
              scale="4 4 4"
              animation-mixer="clip: *; loop: repeat;"
              
            ></a-entity>
          </Marker>
        </AFrameRenderer>
      )}
    </div>
  );
}

export default PeaceDrawingAR;
