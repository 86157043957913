import React, { useState, useEffect } from 'react';
import { AFrameRenderer, Marker } from 'react-web-ar';
import './ARModeUI.css';

function PanzerAR() {
  const [arVisible, setArVisible] = useState(true);

  const handleDisableAR = () => {
    window.location.replace("/Quiz/0");
  };
  
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handleClickBrowserBackBtn = () => {
      // �ڷ� ���� ��ư Ŭ�� �� ����� ����
      // ���⼭�� �ƹ��� ���۵� ���� �ʵ��� �����Ͽ� �ڷ� ���� ��ư�� ����ȭ�˴ϴ�.
      window.history.pushState(null, "", window.location.href); // ���� ���¸� �ٽ� push�Ͽ� �ڷ� ���⸦ ����
    };

    window.addEventListener("popstate", handleClickBrowserBackBtn);

    return () => {
      window.removeEventListener("popstate", handleClickBrowserBackBtn);
    };
  }, []);


  useEffect(() => {
    if (arVisible) {
      // Add event listeners for gestures
      const sceneEl = document.querySelector('a-scene');
      
      sceneEl.addEventListener('onefingermove', handleRotation);
      sceneEl.addEventListener('twofingermove', handleScale);

      // Cleanup function to remove event listeners
      return () => {
        sceneEl.removeEventListener('onefingermove', handleRotation);
        sceneEl.removeEventListener('twofingermove', handleScale);
      };
    }
  }, [arVisible]);



  const handleRotation = (event) => {
    const rotationFactor = 5; // Adjust as needed
    const { positionChange } = event.detail;

    const entity = document.querySelector('.clickable');
    if (entity) {
        // �ʱ� ȸ�� ���� �����Ͽ� ȸ�� ���� �����
        if (Math.abs(positionChange.x) > Math.abs(positionChange.y)) {
            // X�� ��ȭ���� �� ũ�� Z�� ���� ȸ�� (rotation.z)
            entity.object3D.rotation.x += rotationFactor * positionChange.x;
        } else {
            // Y�� ��ȭ���� �� ũ�� X�� ���� ȸ�� (rotation.x)
            entity.object3D.rotation.z += rotationFactor * positionChange.y;
        }

        // Y���� �ʱ� ȸ������ ����
        entity.object3D.rotation.y = Math.PI / 2; // 90��
    }
};
  
  
 
  const handleScale = (event) => {
  const { startSpread, spreadChange } = event.detail;
  const minScale =0.25;
  const maxScale = 10;

  const entity = document.querySelector('.clickable');
  if (entity) {
    let newScale = entity.object3D.scale.x + spreadChange * 0.01;
    newScale = Math.max(minScale, Math.min(maxScale, newScale)); // ������ ������ ����

    entity.object3D.scale.set(newScale, newScale, newScale);
  }
  };
  
  return (
    <div >
      {arVisible && (
        <div style={{position: 'absolute', zIndex: 10, textAlign: 'center ', left: '50%',transform: 'translateX(-50%)',bottom: '10%'}}>
          <button
            class="disable-button"
            onClick={handleDisableAR}
          >
          </button>
        </div>
      )}
      {arVisible && (
        //class="clickable" gesture-detector 
      <AFrameRenderer inherent   gesture-detector renderer="logarithmicDepthBuffer: true;colorManagement: true" vr-mode-ui="enabled: false" arjs="debugUIEnabled: false;"  >
         <a-camera look-controls="enabled: false" wasd-controls="enabled: false"></a-camera>
              
          <Marker parameters={{ preset:"pattern", type:"pattern" ,url:"/marker/pattern-AR.patt"}} >
              
              <a-entity 
              gltf-model="gltf: url(/models/test.gltf)"
              position="0 0 0"
              rotation="180 90 -90"
              scale="0.4 0.4 0.4"
              animation-mixer="clip: *; loop: repeat;"
              gesture-handler="minScale: 0.2; maxScale: 2"
              class="clickable"
              camera look-controls
              ></a-entity>
          </Marker>
      </AFrameRenderer>

      )}
    </div>
  );
}


export default PanzerAR;
