import React, { useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import './Result.css';

function Result() {
    let navigate = useNavigate();
    const [name, setName] = useState('');
    const cardRef = useRef();
    const timerRef = useRef(null);

    const onDownload = () => {
        const card = cardRef.current;
        const filter = (card) => {
            return card.tagName !== 'BUTTON';
        };
        domtoimage
            .toBlob(card, { filter: filter })
            .then((blob) => {
                saveAs(blob, 'card.png');
            });
    };

    const handleStart = () => {
        navigate('/');
    };

    const handleMouseDown = () => {
        timerRef.current = setTimeout(onDownload, 1000); // Adjust the time as needed
    };

    const handleMouseUp = () => {
        clearTimeout(timerRef.current);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    return (

        <div className='reaultBackground'>
            <h1 className='ResultText'>이름 작성 후 아래 카드를 꾹 <br />누르시면 이미지로 저장됩니다.</h1>
            <input
                type="text"
                placeholder="이름을 입력해주세요."
                value={name}
                onChange={handleNameChange}
                className="inputName"
                maxlength='5'
            />
            
            <div className='cardParent'  >
                <div
                    ref={cardRef}
                    className="card"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    
                >
                    <div className="CardTextAlign">
                        <p>축하합니다. {name ? `${name}님` :''} </p>
                        <p>AR 평화문화진지 대탐험</p>
                        <p>성공하셨습니다.</p>
                    </div>
                </div>
            </div>
            <button className="restartBtn" onClick={handleStart}></button>
        </div>
    );
};

export default Result;
