import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h1 style={{fontFamily:"sans-serif", textAlign:"center", marginTop: "20%",fontSize:'3.5rem'}}>404 Error</h1>
            <p style={{fontFamily:"sans-serif", textAlign:"center", marginTop: "10%",fontSize:'1.2rem'}}>Page Not Found</p>
        </div>
    );
};
  
export default NotFound;